#cards {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    height: 500px;
}
.card-stats{
    /* margin: 33px; */
    margin-top: 90px;
    background-color: gainsboro;
}
#card1{
    margin-left: 40px;
}
#card3{
    margin-right: 40px;
}
.bg-default, .table-dark {
    background-color: #fff !important;
}
.table-dark>thead>tr>th {
    color: #fff !important;
}
.fill-default {
    fill: #1f1456; 
}